import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import {
  notifications,
  paths,
  subscriptions as subscriptionConstants,
} from 'src/constants';
import { useIntlMessages } from 'src/hooks';
import { actions, selectors } from 'src/store';
import { subscriptions as subscriptionsUtils } from 'src/utils';

import navbarUtils from '../Navbar/utils';
import navbarHooks from '../Navbar/hooks';
import SettingsMenuItem from '../../../SettingsMenuItem';
import { navbarItemNames } from '../../constants';

const SpeechSoundButton = () => {
  const messages = useIntlMessages();

  return (
    <SettingsMenuItem
      title={messages.label.speechSoundSelection}
      iconName="sound-selection"
      to={paths.SPEECH_SOUND_SELECTION}
    />
  );
};

const SettingsCouponButton = () => {
  const messages = useIntlMessages();
  const subscriptions = useSelector(selectors.subscriptions.getSubscriptions);

  if (subscriptions?.length === 0) {
    return null;
  }

  return (
    <SettingsMenuItem
      title={messages.label.coupon}
      iconName="gift"
      to={paths.SETTINGS_COUPON}
    />
  );
};

const SettingsAppButton = () => {
  const messages = useIntlMessages();

  return (
    <SettingsMenuItem
      title={messages.label.appSettings}
      iconName="settings"
      to={paths.SETTINGS_APP}
    />
  );
};

const SettingsUserButton = () => {
  const messages = useIntlMessages();

  return (
    <SettingsMenuItem
      title={messages.label.userSettings}
      iconName="user"
      to={paths.SETTINGS_USER}
    />
  );
};

const LogoutButtonSettings = () => {
  const messages = useIntlMessages();
  const { logout } = navbarHooks.useLogout();

  return (
    <SettingsMenuItem
      title={messages.label.logout}
      iconName="logout"
      type="danger"
      onClick={logout}
    />
  );
};

const PaymentButtonSettings = () => {
  const messages = useIntlMessages();
  const subscriptions = useSelector(selectors.subscriptions.getSubscriptions);

  if (subscriptions?.length === 0) {
    return null;
  }

  return (
    <SettingsMenuItem
      title={messages.label.activeSubscription}
      iconName="card"
      to={paths.SUBSCRIPTION_STATUS}
    />
  );
};

const SettingsDailyMapButton = () => {
  const messages = useIntlMessages();

  return (
    <SettingsMenuItem
      title={messages.label.dailyMapSettings}
      iconName="map"
      to={paths.SETTINGS_DAILY_MAP}
    />
  );
};

const useIsStatisticsAvailable = () => {
  const allSubscriptions = useSelector(
    selectors.subscriptions.getSubscriptions
  );
  const activeSubscriptionKey = useSelector(
    selectors.authentication.getActiveSubscriptionKey
  );

  return useMemo(
    () =>
      allSubscriptions &&
      (subscriptionsUtils.getIsSpeechTherapistSubscription(
        activeSubscriptionKey
      ) ||
        activeSubscriptionKey === subscriptionConstants.KEYS.PREMIUM),
    [activeSubscriptionKey, allSubscriptions]
  );
};

const StatisticsButton = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const messages = useIntlMessages();
  const isStatisticsAvailable = useIsStatisticsAvailable();

  return (
    <SettingsMenuItem
      title={messages.label.statistics}
      iconName="statistic"
      type={!isStatisticsAvailable ? 'neutral' : 'primary'}
      onClick={() => {
        if (isStatisticsAvailable) {
          history.push(paths.STATISTICS);
          return;
        }

        dispatch(
          actions.notifications.showNotification(
            messages.notification.statistics,
            notifications.type.INFO,
            notifications.duration.SHORT
          )
        );
      }}
    />
  );
};

const HelpButtonSettings = () => {
  const messages = useIntlMessages();
  const history = useHistory();
  const location = useLocation();
  const { helpKey } = navbarUtils.getNavbarItems(location.pathname);

  return (
    <SettingsMenuItem
      title={messages.label.help}
      iconName="questionmark"
      type={
        window.location.pathname !== paths.SPEECH_THERAPIST
          ? 'primary'
          : 'success'
      }
      onClick={() =>
        history.push(`${paths.HELP}${helpKey ? `?key=${helpKey}` : ''}`)
      }
    />
  );
};

const SpeechTherapistStudentButton = () => {
  const messages = useIntlMessages();
  const student = useSelector(selectors.authentication.getStudent);

  return (
    <SettingsMenuItem
      title={messages.label.studentSettings}
      iconName="user"
      to={paths.SETTINGS_STUDENT.replace(':studentId', student.id)}
    />
  );
};

const SpeechTherapistBackButton = () => {
  const messages = useIntlMessages();

  return (
    <SettingsMenuItem
      title={messages.label.dashboard}
      iconName="back"
      to={paths.SPEECH_THERAPIST}
    />
  );
};

const KokolingoEkspertButton = () => {
  const messages = useIntlMessages();

  const subscriptionStatus = useSelector(
    selectors.subscriptionStatus.getSubscriptionStatus
  );

  const isEkspertEnabled = subscriptionsUtils.getIsEkspertEnabled();
  const hasExpertDomain = !!subscriptionStatus?.hasExpertDomain;

  if (!isEkspertEnabled || hasExpertDomain) {
    return null;
  }

  return (
    <SettingsMenuItem
      title={messages.label.kokolingoEkspert}
      variant="green"
      type="green"
      iconName="info"
      onClick={() => {
        window.open(paths.landingPage.KOKOLINGO_EKSPERT, '_blank');
      }}
    />
  );
};

const AcademyButton = () => {
  const messages = useIntlMessages();

  const subscriptionStatus = useSelector(
    selectors.subscriptionStatus.getSubscriptionStatus
  );

  const isEkspertEnabled = subscriptionsUtils.getIsEkspertEnabled();
  const hasExpertDomain = !!subscriptionStatus?.hasExpertDomain;

  if (!isEkspertEnabled || hasExpertDomain) {
    return null;
  }

  return (
    <SettingsMenuItem
      title={messages.label.academy}
      variant="green"
      type="green"
      iconName="info"
      onClick={() => {
        window.open(paths.landingPage.ACADEMY, '_blank');
      }}
    />
  );
};

const SETTINGS_BUTTONS = {
  [navbarItemNames.SPEECH_SOUND]: SpeechSoundButton,
  [navbarItemNames.SETTINGS_COUPON]: SettingsCouponButton,
  [navbarItemNames.SETTINGS_APP]: SettingsAppButton,
  [navbarItemNames.SETTINGS_USER]: SettingsUserButton,
  [navbarItemNames.LOGOUT]: LogoutButtonSettings,
  [navbarItemNames.PAYMENT]: PaymentButtonSettings,
  [navbarItemNames.SETTINGS_DAILY_MAP]: SettingsDailyMapButton,
  [navbarItemNames.STATISTICS]: StatisticsButton,
  [navbarItemNames.HELP]: HelpButtonSettings,
  [navbarItemNames.SPEECH_THERAPIST_STUDENT]: SpeechTherapistStudentButton,
  [navbarItemNames.SPEECH_THERAPIST_BACK]: SpeechTherapistBackButton,
  [navbarItemNames.KOKOLINGO_EKSPERT]: KokolingoEkspertButton,
  [navbarItemNames.ACADEMY]: AcademyButton,
};

export default { SETTINGS_BUTTONS };
