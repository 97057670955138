import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { compose } from 'redux';
import cn from 'classnames';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Localize } from '@kokolingo/localisation';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import _isEmpty from 'lodash/isEmpty';

import { actions, selectors } from 'src/store';
import { notifications, paths, checkpoints } from 'src/constants';
import {
  player,
  common,
  subscriptions as subscriptionsUtils,
  date,
} from 'src/utils';
import {
  useQuery,
  useIntlMessages,
  useCheckpoint,
  useEkspertPromo,
  usePromo,
  use50Chance,
} from 'src/hooks';
import config from 'src/config.json';

import {
  Badge,
  Container,
  PageLoader,
  Parrot,
  Navbar,
  Zoom,
  Topbar,
  Button,
  Tooltip,
  ModalShop,
  Modal,
  SpeechSoundBadge,
  Label,
  ModalPromo,
} from 'src/components/common';

import './index.scss';
import './mapPoints.scss';

const USER_POINTS_CHECKPOINT = 20;

const pointPriorities = {
  10: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  9: [1, 2, 3, 4, 5, 6, 7, 9, 10],
  8: [1, 2, 3, 4, 6, 7, 9, 10],
  7: [1, 2, 4, 5, 7, 9, 10],
  6: [1, 3, 4, 5, 8, 10],
  5: [1, 3, 5, 8, 10],
  4: [1, 4, 8, 10],
};

const Map = ({
  activityGroupsData,
  getActivityGroupsData,
  getSpeechSoundById,
  hasLoaded,
  showNotification,
  student,
  studentSpeechSound,
  updateStudentById,
  hasActiveSubscription,
  setShowTrialEndedModal,
  user,
  subscriptionStatus,
  getStatus,
  isSpeechTherapist,
  setTooltipVisibility,
  userTotalPoints,
  subscriptions,
}) => {
  const history = useHistory();
  const query = useQuery();
  const messages = useIntlMessages();

  const is50Chance = use50Chance();

  const {
    showModal: showEkspertPromoModal,
    hasAutoShowModal: hasAutoShowEkspertPromoModal,
    handleShowModal: handleEkspertPromoShowModal,
    handleClose: handleEkspertPromoClose,
    handleCTAClick: handleEkspertPromoCTAClick,
  } = useEkspertPromo();

  const {
    showModal: showPromoModal,
    setShowModal: setShowPromoModal,
    handleShowModal: handlePromoShowModal,
    handleClose: handlePromoClose,
    handleCTAClick: handlePromoCTAClick,
  } = usePromo();

  const [showPasswordModal, setShowPasswordModal] = useState(
    !!user.shouldSetPassword
  );

  useCheckpoint(checkpoints.FIRST_TIME_ON_MAP, hasLoaded);
  useCheckpoint(checkpoints.SECOND_TIME_ON_MAP, hasLoaded, [
    checkpoints.FIRST_TIME_ON_MAP,
  ]);
  useCheckpoint(
    checkpoints.FIRST_TIME_OVER_20_POINTS,
    hasLoaded && userTotalPoints > USER_POINTS_CHECKPOINT,
    [checkpoints.FIRST_TIME_ON_MAP, checkpoints.SECOND_TIME_ON_MAP]
  );

  const mapClassNames = useMemo(
    () =>
      cn(`map-container`, {
        [`map-container--special`]: studentSpeechSound.isSpecial,
      }),
    [studentSpeechSound]
  );

  useEffect(() => {
    if (query.has('email-verified')) {
      showNotification(
        messages.notification.emailConfirmed,
        notifications.type.SUCCESS,
        notifications.duration.SHORT
      );

      history.push(paths.buildQuery(paths.MAP, { userId: user.id }));
    }

    const newSubscription = query.get('new-subscription');
    const price = query.get('price');
    const subscriberHashedEmail = query.get('subscriberEmail');

    if (newSubscription && price) {
      window.dataLayer.push({
        event: 'purchase',
        subscription: newSubscription,
        value: Number(price),
        currency: 'EUR',
        subscriberEmail: subscriberHashedEmail,
      });
    }

    if (query.has('acceptedInvite')) {
      showNotification(
        messages.notification.speechTherapistInviteAccepted,
        notifications.type.SUCCESS,
        notifications.duration.LONG
      );
    }

    if (!query.get('userId')) {
      history.replace(paths.buildQuery(paths.MAP, { userId: user.id }));
    }
  }, [history, query, showNotification, user.id, messages]);

  const handleVideoEnded = useCallback(
    (educationFlag) => {
      updateStudentById(player.getSelectedStudentId(), {
        [educationFlag]: true,
      });
    },
    [updateStudentById]
  );

  useEffect(() => {
    if (activityGroupsData.length === 0) {
      getActivityGroupsData();
    }
  }, [getActivityGroupsData, activityGroupsData]);

  useEffect(() => {
    getSpeechSoundById(student.SpeechSoundId);
  }, [getSpeechSoundById, student]);

  useEffect(() => {
    if (_isEmpty(subscriptionStatus)) {
      getStatus();
    }
  }, [getStatus, subscriptionStatus]);

  const createActivityRedirect = useCallback(
    (activityGroupKey, tooltipId) => () => {
      setTooltipVisibility(tooltipId, false);
      if (!hasActiveSubscription) {
        setShowTrialEndedModal(true);
        return;
      }
      history.push(paths.build(paths.ACTIVITY, activityGroupKey));
    },
    [
      setTooltipVisibility,
      hasActiveSubscription,
      history,
      setShowTrialEndedModal,
    ]
  );

  if (!hasLoaded) {
    return <PageLoader />;
  }

  const tooltip = ['firstIsland'];

  const isPromoDate = date.getIsPromoDate();
  const hasSubscription = subscriptionStatus.isSubscription;
  const hasExpertDomain = !!subscriptionStatus.hasExpertDomain;

  const showStandardPromo = !hasSubscription && subscriptions.length > 0;
  const showKokolingoEkspertPromo =
    subscriptionsUtils.getIsEkspertEnabled() &&
    !hasExpertDomain &&
    (isPromoDate ? hasSubscription : hasSubscription || is50Chance);

  const showPromoButton =
    !isSpeechTherapist && (showStandardPromo || showKokolingoEkspertPromo);

  let specialOfferLabel = 'page.subscriptionSelection.promotion';

  if (showKokolingoEkspertPromo) {
    specialOfferLabel =
      'page.subscriptionSelection.promotion.kokolingo.ekspert';
  } else if (isPromoDate) {
    specialOfferLabel = 'page.subscriptionSelection.promotion.offer';
  }

  return (
    <PageLoader isFadingOut>
      {/* modal is here because we don't want to break playing flow */}
      <ModalShop />
      <ModalPromo
        type="ekspert"
        showModal={
          showEkspertPromoModal &&
          (isPromoDate ? showKokolingoEkspertPromo : true)
        }
        onShowModal={handleEkspertPromoShowModal}
        onClose={handleEkspertPromoClose}
        onCTAClick={handleEkspertPromoCTAClick}
      />
      <ModalPromo
        type="offer"
        showModal={
          showPromoModal &&
          (!showKokolingoEkspertPromo || !hasAutoShowEkspertPromoModal)
        }
        onShowModal={handlePromoShowModal}
        onClose={handlePromoClose}
        onCTAClick={handlePromoCTAClick}
      />
      {user.shouldSetPassword && (
        <Modal
          className="set-password-modal"
          show={showPasswordModal}
          onClose={() => setShowPasswordModal(false)}
          onOutsideClick={() => setShowPasswordModal(false)}
        >
          <Modal.Title>
            <Localize id="modal.shouldSetPassword.title" />
          </Modal.Title>
          <Modal.Description>
            <Localize id="modal.shouldSetPassword.description" />
          </Modal.Description>
        </Modal>
      )}
      <Container className={mapClassNames}>
        <Navbar.Title
          title={
            !isSpeechTherapist
              ? student.fullName
              : student.fullNameForSpeechTherapist
          }
          icon={<SpeechSoundBadge speechSound={studentSpeechSound} />}
        />
        <Navbar.Actions>
          <Navbar.FreeTrialAction />
          {process.env.REACT_APP_DAILY_MAP === 'true' && (
            <Link to={paths.DAILY_MAP}>
              <Badge iconName="map" />
            </Link>
          )}
          <Navbar.EducationAction
            videos={config.videos.map}
            handleVideoEnded={handleVideoEnded}
          />
        </Navbar.Actions>
        {showPromoButton && (
          <Topbar>
            <Button
              collapsed
              variant="register"
              className="map-container__action"
              onClick={() => {
                if (showKokolingoEkspertPromo) {
                  window.open(paths.landingPage.KOKOLINGO_EKSPERT, '_blank');

                  return;
                }

                if (isPromoDate) {
                  setShowPromoModal(true);
                  return;
                }

                history.push(paths.SUBSCRIPTIONS);
              }}
            >
              <b>
                <Localize id={specialOfferLabel} />
              </b>
            </Button>
          </Topbar>
        )}
        <Zoom mobileWidth={360} mobileHeight={640} align="center">
          <Container className="map-container__filled">
            <>
              <Tooltip id="firstIsland">
                <Localize id="tooltip.firstIsland" />
              </Tooltip>
              {activityGroupsData.map((activityGroupData, index) => {
                const positions = pointPriorities[activityGroupsData.length];

                return (
                  <>
                    <Badge
                      id={`point-${positions[index]}`}
                      className={`point point-${positions[index]}`}
                      size="regular"
                      type={common.getActivityIconBackground(
                        activityGroupData.key
                      )}
                      key={activityGroupData.key}
                      onClick={createActivityRedirect(
                        `${activityGroupData.key}-${activityGroupData.id}`,
                        tooltip[index]
                      )}
                      tooltip={tooltip[index]}
                    >
                      {(index + 1).toString()}
                    </Badge>
                    {activityGroupData.label && (
                      <Label
                        className={`point point-${positions[index]} point-label`}
                        htmlFor={`point-${positions[index]}`}
                        label={activityGroupData.label}
                      />
                    )}
                  </>
                );
              })}
            </>

            <Link to={paths.SHOP}>
              <Parrot
                className="map-container__parrot"
                height={isMobile ? '100px' : '300px'}
              />
            </Link>
          </Container>
        </Zoom>
      </Container>
    </PageLoader>
  );
};

Map.propTypes = {
  getActivityGroupsData: PropTypes.func.isRequired,
  getSpeechSoundById: PropTypes.func.isRequired,
  updateStudentById: PropTypes.func.isRequired,
  activityGroupsData: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
    })
  ),
  hasLoaded: PropTypes.bool.isRequired,
  hasActiveSubscription: PropTypes.bool.isRequired,
  showNotification: PropTypes.func.isRequired,
  setShowTrialEndedModal: PropTypes.func.isRequired,
  student: PropTypes.shape({
    SpeechSoundId: PropTypes.number,
    fullName: PropTypes.string,
    fullNameForSpeechTherapist: PropTypes.string,
    hasPassedActivityGroupEducation: PropTypes.bool,
    hasPassedMapIconsEducation: PropTypes.bool,
    hasPassedWelcomeEducation: PropTypes.bool,
  }).isRequired,
  studentSpeechSound: PropTypes.shape({
    label: PropTypes.string,
    isSpecial: PropTypes.bool,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    totalPoints: PropTypes.number,
    meta: PropTypes.object,
    shouldSetPassword: PropTypes.bool,
  }).isRequired,
  subscriptions: PropTypes.arrayOf({}).isRequired,
  subscriptionStatus: PropTypes.shape({
    isTrial: PropTypes.bool,
    isSubscription: PropTypes.bool,
    hasExpertDomain: PropTypes.bool,
    daysLeft: PropTypes.number,
    pointsLeft: PropTypes.number,
    subscriptionExpirationDate: PropTypes.string,
  }).isRequired,
  getStatus: PropTypes.func.isRequired,
  isSpeechTherapist: PropTypes.bool.isRequired,
  setTooltipVisibility: PropTypes.func.isRequired,
  userTotalPoints: PropTypes.number.isRequired,
};

Map.defaultProps = {
  activityGroupsData: [],
};

const mapStateToProps = (state) => ({
  activityGroupsData: selectors.activityGroups.getActivityGroupsData(state),
  hasLoaded: selectors.activityGroups.getHasLoaded(state),
  hasReceivedTrialExpirationNotification: selectors.authentication.getHasReceivedTrialExpirationNotification(
    state
  ),
  hasReceivedUpdateNotification: selectors.authentication.getHasReceivedUpdateNotification(
    state
  ),
  isSpeechTherapist: selectors.authentication.getIsSpeechTherapist(state),
  student: selectors.authentication.getStudent(state),
  studentSpeechSound: selectors.speechSounds.getSpeechSound(state),
  hasActiveSubscription: selectors.subscriptionStatus.getHasActiveSubscription(
    state
  ),
  user: selectors.authentication.getUser(state),
  subscriptions: selectors.subscriptions.getSubscriptions(state),
  subscriptionStatus: selectors.subscriptionStatus.getSubscriptionStatus(state),
  userTotalPoints: selectors.user.getTotalPoints(state),
});

const mapDispatchToProps = {
  ...actions.activityGroups,
  ...actions.authentication,
  ...actions.notifications,
  ...actions.speechSounds,
  ...actions.students,
  ...actions.subscriptions,
  ...actions.tooltips,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), memo)(Map);
